.header {
  color: white;
  padding: 10px 0;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header-text {
  display: block;
  margin: 0;
}

.header-text > h1 {
  font-size: 24px;
  color: #00fab4;
  margin: 0 auto;
}

.header-text > span {
  font-size: 14px;
}

.header-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.header-nav li {
  margin: 0 15px;
}

.header-nav a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.header-nav a:hover {
  color: #00fab4;
}
