.blog-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Use the parent element's color */
}

.blog-link:hover {
  color: #007bff; /* Change to your desired hover color */
}

.blog-item {
  border-bottom: 1px solid white;
  margin-bottom: 12px;
  text-align: center;
}

h5 {
  white-space: pre-wrap;
}

@media (min-width: 992px) {
  h5 {
    white-space: normal;
  }
}